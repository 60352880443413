import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

/* const { 
    REACT_APP_APIKEY, REACT_APP_AUTHDOMAIN, REACT_APP_PROJECTID,  REACT_APP_STORAGEBUCKET,  REACT_APP_MESSAGINGSENDERID,  REACT_APP_APPID, REACT_APP_MEASUREMENTID 
} = process.env
 */
const firebaseConfig = {
  apiKey: "AIzaSyCcq-_f3byfA1KAegr1XFcntx-cuoKbOhE",
  authDomain: "xcomerce-4f8ca.firebaseapp.com",
  projectId: "xcomerce-4f8ca",
  storageBucket: "xcomerce-4f8ca.appspot.com",
  messagingSenderId: "136598970023",
  appId: "1:136598970023:web:78a48201cfa6b41997c582",
  measurementId: "G-ZX3S8536N3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const provider = new GoogleAuthProvider()
const analytics = getAnalytics(app);
