import {
    Stack,
    Container,
    Box,
    Flex,
    Text,
    Avatar,
    Heading,
    SimpleGrid,
    useColorModeValue
} from '@chakra-ui/react';
//import Section from '../layouts/SectionMotion'

const testimonials = [
    {
        name: 'Brandon P.',
        role: 'Chief Marketing Officer',
        content:
            'Realmente me ahorra tiempo y esfuerzo. Es exactamente lo que le ha faltado a nuestro negocio. EEZY es el recurso comercial más valioso que jamás hayamos comprado. ¡Después de usar EEZY, mi negocio se disparó!',
        avatar:
            'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
    },
    {
        name: 'Krysta B.',
        role: 'Entrepreneur',
        content:
            "Ni siquiera necesité entrenamiento. Hemos usado EEZY durante los últimos cinco años. Obtuve al menos 50 veces el valor de EEZY. ¡Recuperé el precio de compra en solo 48 horas!",
        avatar:
            'https://images.unsplash.com/photo-1598550874175-4d0ef436c909?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
    },
    {
        name: 'Darcy L.',
        role: 'Movie star',
        content:
            "¡Gracias por hacerlo sin dolor, agradable y, sobre todo, sin complicaciones! Estoy bien para irme. No importa a dónde vayas, ¡EEZY es lo mejor y lo más divertido que existe! Me encanta EEZY!",
        avatar:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=334&q=80',
    },
    {
        name: 'Daniel T.',
        role: 'Musician',
        content:
            'I am so pleased with this product. EEZY is both attractive and highly adaptable. Without EEZY, we would have gone bankrupt by now. Thank you for creating this product!',
        avatar:
            'https://images.unsplash.com/photo-1606513542745-97629752a13b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
    },
];
const Testimonial = ({ children }) => {
    return (
        <Box >
            {children}
        </Box>
    )
}

const TestimonialContent = ({ children }) => {
    return (
        <Stack
            bg={useColorModeValue('gray.100', 'gray.700')}
            boxShadow={'lg'}
            p={6}
            rounded={'xl'}
            align={'center'}
            pos={'relative'}
            _after={{
                content: `""`,
                w: 0,
                h: 0,
                borderLeft: 'solid transparent',
                borderLeftWidth: 16,
                borderRight: 'solid transparent',
                borderRightWidth: 16,
                borderTop: 'solid',
                borderTopWidth: 16,
                borderTopColor: useColorModeValue('gray.100', 'gray.700'),
                pos: 'absolute',
                bottom: '-16px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}
        >
            {children}
        </Stack>
    )
}
const TestimonialHeading = ({ children }) => {
    return (
        <Heading as={'h3'} fontSize={'xl'}>
            {children}
        </Heading>
    )
}
const TestimonialText = ({ children }) => {
    return (
        <Text
            textAlign={'center'}
            color={useColorModeValue('gray.600', 'gray.400')}
            fontSize={'sm'}>
            {children}
        </Text>
    )
}
const TestimonialAvatar = ({ src, name, title }) => {
    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Avatar src={src} alt={name} mb={2} />
            <Stack spacing={-1} align={'center'}>
                <Text fontWeight={600}>{name}</Text>
                <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
                    {title}
                </Text>
            </Stack>
        </Flex>
    );
};

export default function SectionMid() {
    return (
        <Box >
            <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
                    <Stack spacing={2} my={9} align={'center'}>
                    </Stack>
                    <Stack direction={{ base: 'column', md: 'row' }}
                        spacing={{ base: 10, md: 4, lg: 10 }}>

                        <Testimonial>
                            <TestimonialContent>
                                <TestimonialHeading>
                                    
                                </TestimonialHeading>
                                <TestimonialText>{testimonials[1].content}</TestimonialText>
                            </TestimonialContent>
                            <TestimonialAvatar
                                src={testimonials[1].avatar}
                                name={testimonials[1].name}
                                title={testimonials[1].role}
                            />
                        </Testimonial>
                        <Testimonial>
                            <TestimonialContent>
                                <TestimonialHeading>
                                </TestimonialHeading>
                                <TestimonialText>{testimonials[2].content}</TestimonialText>
                            </TestimonialContent>
                            <TestimonialAvatar
                                src={testimonials[2].avatar}
                                name={testimonials[2].name}
                                title={testimonials[2].role}
                            />
                        </Testimonial>
                        <Testimonial>
                            <TestimonialContent>
                                <TestimonialHeading>
                                </TestimonialHeading>
                                <TestimonialText>{testimonials[0].content}</TestimonialText>
                            </TestimonialContent>
                            <TestimonialAvatar
                                src={testimonials[0].avatar}
                                name={testimonials[0].name}
                                title={testimonials[0].role}
                            />
                        </Testimonial>
                    </Stack>
                
            </Container>
        </Box>
    )
}











