import {
    Button,
    ButtonGroup,
    Container,
    Divider,
    IconButton,
    Input,
    Stack,
    Text,
} from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'

const Footer = () => (
    <Container maxW={'7xl'} >
        <Divider />
        <Stack
            pt="8"
            pb="12"
            justify="space-between"
            direction={{
                base: 'column-reverse',
                md: 'row',
            }}
            align="center"
        >
            <Text fontSize="sm" color="subtle">
                &copy; {new Date().getFullYear()} nombre de la empresa y todos los derechos reservados
            </Text>
            <ButtonGroup variant="ghost">
                <IconButton
                    as="a"
                    href="#"
                    aria-label="LinkedIn"
                    icon={<FaLinkedin fontSize="1.25rem" />}
                />
                <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />
                <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />
            </ButtonGroup>
        </Stack>
    </Container>
)
export default Footer