import React from 'react'
import { Stack, SimpleGrid } from '@chakra-ui/react'
import ItemCard from './ItemCard'
import Section from '../../layouts/SectionMotion'

function CardGroupt() {

    return (
        <Stack
            align={'center'}
            justify={'center'}>
                <Section>
                    <Stack spacing={6} direction={{ base: 'column', md: 'row' }} >
                        <SimpleGrid>
                            <ItemCard flex={1}/>
                        </SimpleGrid>
                        <SimpleGrid>
                            <ItemCard flex={1}/>
                        </SimpleGrid>
                        <SimpleGrid>
                            <ItemCard flex={1}/>
                        </SimpleGrid>
                    </Stack>
                </Section>
        </Stack>
    )
}

export default CardGroupt