import React from 'react';
import { useAuth } from '../../context/authContext'
import Hero from '../../components/headers/Hero'
import SectionMid from '../../components/SectionMid'
import Section from '../../layouts/SectionMotion'
import Layout from '../../layouts/ArticleMotion'

const Home = () => {
    const { user } = useAuth();
    return (
        <Layout>
            <Section>
                <Hero />
            </Section>
            <Section delay={0.2}>
                <SectionMid />
            </Section>
        </Layout>
    );
}

export default Home;
