import React, { useState } from 'react'
import { Box, Divider, Grid, GridItem, Image, Text,
  chakra, SimpleGrid , Stat, StatLabel,  StatNumber,  useColorModeValue, Collapse, Button, ListItem, List, ListIcon,
} from '@chakra-ui/react'
import { useAuth } from '../../context/authContext';
import { SiFirebase, SiJavascript, SiMaterialUi, SiReact  } from 'react-icons/si'

const ViewProfile = () => {
    const {user} = useAuth();
    const [ show , setShow] = useState(false)

    const handleShow = () => setShow(!show)


    function StatsCard(props) {
      const { title, stat } = props;
      return (
        <Stat
          px={{ base: 4, md: 8 }}
          py={'5'}
          shadow={'xl'}
          border={'1px solid'}
          borderColor={useColorModeValue('gray.800', 'gray.500')}
          rounded={'lg'}>
          <StatLabel fontWeight={'large'}>
            {title}
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Stat>
      );
    }


  return (

        <Grid  
        templateRows='repeat(2, 1fr)'
        templateColumns='repeat(3, 1fr)'
        gap={2} >
            <div>
                <Text fontSize='6xl'>Bienvenido a su perfil  </Text>
                {user.displayName} 

            <div>                 
                <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
                <chakra.h1
                  textAlign={'center'}
                  fontSize={'4xl'}
                  py={10}
                  fontWeight={'bold'}>
                  ¿ Quien soy ?
                </chakra.h1>
                <SimpleGrid >
                <Collapse startingHeight={100} in={show} > 
                  <StatsCard title={'Full-Stack Developer'} stat={'Soy un apasionado por las tecnologías con bases en Javascript  React JS NodeJS con experiencia en la creación de SPA en el area de Front-End y Back-End Fui funcionario público en Gendarmería Nacional Argentina por eso me considero alguien responsable y disciplinado Me motiva el deseo de saber y aprender cada vez mas en el mundo IT'} />
                </Collapse>
                <Button size='sm' onClick={handleShow} mt='1rem'>
                  Show {show ? 'Less' : 'More'}
                </Button>
                </SimpleGrid>
              </Box>
            </div>           
            </div>
              <Divider  orientation='vertical' />
            <div>
                <Image 
                src='https://bit.ly/dan-abramov' 
                alt='Dan Abramov' 
                borderRadius='full'
                boxSize='450'
                marginLeft='200'
                marginBottom='80'
                />
            </div>
            
            <div>
            <SimpleGrid>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={SiJavascript} color='green.500' />
              </ListItem>
              <ListItem>
                <ListIcon as={SiReact} color='green.500' />
              </ListItem>
              <ListItem>
                <ListIcon as={SiFirebase} color='green.500' />
              </ListItem>
              <ListItem>
                <ListIcon as={SiMaterialUi} color='green.500' />
              </ListItem>
            </List>
          </SimpleGrid> 
            </div>
            
        </Grid>

  )
}

export default ViewProfile