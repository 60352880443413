import React from 'react'
import { Box, useColorModeValue, Stack, Grid, SimpleGrid, GridItem } from '@chakra-ui/react'
import { db } from '../firebase-config'
import { ref, set } from 'firebase/firestore'
import Section from '../layouts/SectionMotion'
import CardGroupt from '../components/Cards/CardGroupt'


const getData = async () => {

}

function CardsCarrouselForms() {
    return (
            <Stack
                minH={'120vh'}
                align={'center'}
                justify={'center'}>
                        
                    <Section>
                        <Box>   
                            <CardGroupt />
                            <CardGroupt />
                            <CardGroupt />
                        </Box>
                    </Section>

            </Stack>

        
    )
}

export default CardsCarrouselForms


