import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
} from '@chakra-ui/react';
import Login from './pages/Login/Login'
import Register from './pages/Login/Register'
import Navbar from './components/nav/Navbar'
import Footer from './components/footer/Footer'
import { AuthProvider } from './context/authContext';
import { ProtectedRoute } from "./routes/ProtectedRoute"
import Home from './pages/Home/Home'
import CardsCarrouselForms from './pages/CardsCarrouselForms'
import FormCard from './components/StepUser/FormUserPostulante'
import UserProfileEdit from './pages/Profile/Perfil';
import ViewProfile from './components/Views/ViewProfile';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <AuthProvider>
          <Navbar />
            <Router >
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/Inicio" element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                } />
                <Route path="/Productos" element={
                  <ProtectedRoute>
                    <CardsCarrouselForms />
                  </ProtectedRoute>
                } />
                <Route path="/Cargar" element={
                  <ProtectedRoute>
                    <FormCard />
                  </ProtectedRoute>
                } />
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/viewProfile" element={<ViewProfile/>} />
                <Route path="/perfil" element={<UserProfileEdit />} />
              </Routes>
            </Router>
            <Footer />
          </AuthProvider>
        </Grid>
      </Box>
    </ChakraProvider>
  )
}

export default App;
